import React, { useState } from "react";
import Layout from "../../../../components/Layout";
import Seo from "../../../../components/seo";
import bgImage from "../../../../images/aichapter/vidura-hero-bg.png";
import HealthBgImg from "../../../../images/aichapter/vidura-health-1.png";
import InsuranceBgImg from "../../../../images/aichapter/vidura-insurance-1.png";
import HumanResourceBgImg from "../../../../images/aichapter/vidura-human-resource-1.png";
import LegalDeptBgImg from "../../../../images/aichapter/vidura-legal-d-1.png";
import GovtPublicBgImg from "../../../../images/aichapter/vidura-govt-1.png";
import RecruitingBgImg from "../../../../images/aichapter/vidura-recruiting-1.png";
import BankFinanceBgImg from "../../../../images/aichapter/vidura-bank-1.png";
import HealthSvgImg from "../../../../images/aichapter/vidura-heart-health-01.svg";
import InsuranceSvgImg from "../../../../images/aichapter/vidura-insurance.svg";
import HumanSvgImg from "../../../../images/aichapter/vidura-human-resource-1.svg";
import LegalSvgImg from "../../../../images/aichapter/vidura-legal.svg";
import GovtPublicSvgImg from "../../../../images/aichapter/vidura-govt.svg";
import RecruitingSvgImg from "../../../../images/aichapter/vidura-recruiting.svg";
import BankSvgImg from "../../../../images/aichapter/vidura-bank.svg";
import ogIMage from "../../../../images/Og-Vidura.png";
import FeatureImgVidura from "../../../../images/aichapter/Feature-of-vidura.svg";
import AppViduraVideo from "../../../../images/aichapter/videos/app-vidura.mp4";
import FeatureViduraOneSVG from "../../../../images/aichapter/feature-vidura-1.svg";
import FeatureViduraTwoSVG from "../../../../images/aichapter/feature-vidura-2.svg";
import FeatureViduraThreeSVG from "../../../../images/aichapter/feature-vidura-3.svg";
import FeatureViduraFourSVG from "../../../../images/aichapter/feature-vidura-4.svg";
import FeatureViduraFiveSVG from "../../../../images/aichapter/feature-vidura-5.svg";
import * as DocumentUnderstanding from "../../../../animations/document-understanding.json";
import * as ContextualInsights from "../../../../animations/integration.json";
import * as StreamLineSupport from "../../../../animations/streamline-support.json";
import { Link } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";
import FeaturesOfDemos from "../../../../components/FeaturesOfDemos";
import Lottie from "react-lottie";
import NavigateBlockV2 from "../../../../components/NavigateBlockV2";
import ExplainedDemoAI from "../../../../components/ExplainedDemoAI";
import { Helmet } from "react-helmet";
import ViduraChatbotWrapper from "../../../../components/ViduraChatbotWrapper";
import CountIncrement from "../../../../components/CountIncrement";

const IDPVidura = () => {
    const [showViduraChatbot, setShowViduraChatbot] = useState(false);
    const handleViduraChatbot = (e) => {
        e.preventDefault();
        setShowViduraChatbot(true);
    };
    const NavbarTheme = {
        color: "light",
        paddingTop: "0px",
    };
    const ListOfViduraFeatures = [
        {
            id: 1,
            imageSrc: FeatureViduraOneSVG,
            title: "Intelligent Document Parsing",
            description:
                "Vidura intelligently parses and understands documents, extracting key insights and information with unmatched accuracy.",
        },
        {
            id: 2,
            imageSrc: FeatureViduraTwoSVG,
            title: "Precision Answers",
            description:
                "The advanced capabilities enable delivery of precise answers based on context and past interactions, ensuring users receive the most relevant information tailored to their needs.",
        },
        {
            id: 3,
            imageSrc: FeatureViduraThreeSVG,
            title: "Seamless Integration",
            description:
                "Our AI solution seamlessly integrates with existing systems and workflows, allowing for effortless implementation and maximum efficiency.",
        },
        {
            id: 4,
            imageSrc: FeatureViduraFourSVG,
            title: "Enhanced User Experience",
            description:
                "Vidura is designed to enhance the user experience, providing intuitive access to information and empowering users to make informed decisions with ease.",
        },
        {
            id: 5,
            imageSrc: FeatureViduraFiveSVG,
            title: "Customizable Solutions",
            description:
                "Tailored to your unique needs, our intelligent document processing platform offers customizable solutions to fit seamlessly into your existing workflow and support processes.",
        },
    ];
    const ListOfExplainAI = [
        {
            id: 1,
            btnTitle: "Healthcare and Life Sciences",
            svgImg: HealthSvgImg,
            title: "Streamline Patient Care & Insurance Processes",
            content:
                "Extract vital patient data from health intake forms, insurance claims, and pre-authorization forms. Maintain data integrity and eliminate manual review efforts with our precise data extraction.",
            image: HealthBgImg,
        },
        {
            id: 2,
            btnTitle: "Insurance",
            svgImg: InsuranceSvgImg,
            title: "Overcome document layout challenges",
            content:
                "Get information from quotes, insurance forms, claims, and receipts. Quickly and accurately extract essential details like case ID and property addresses using our advanced features.",
            image: InsuranceBgImg,
        },
        {
            id: 3,
            btnTitle: "Human Resources",
            svgImg: HumanSvgImg,
            title: "Simplify HR operations",
            content:
                "Support for policy management, resource allocation, and leave management. Instantly access essential HR policies and resources, streamlining processes and promoting organizational efficiency.",
            image: HumanResourceBgImg,
        },
        {
            id: 4,
            btnTitle: "Legal Departments",
            svgImg: LegalSvgImg,
            title: "Streamline legal document analysis",
            content:
                "Ensure regulatory compliance, and enhance case management efficiency through automated workflows and streamlined contract reviews.",
            image: LegalDeptBgImg,
        },
        {
            id: 5,
            btnTitle: "Government and Public Services",
            svgImg: GovtPublicSvgImg,
            title: "Expedite decision-making processes",
            content:
                "Leverage document understanding AI to automate paperwork, enhance data accuracy, and expedite decision-making processes within government and public services agencies.",
            image: GovtPublicBgImg,
        },
        {
            id: 6,
            btnTitle: "Recruiting Firms",
            svgImg: RecruitingSvgImg,
            title: "Accelerate talent acquisition",
            content:
                "Accelerate talent acquisition processes and enhance candidate experiences with automated workflows powered by intelligent document understanding technology.",
            image: RecruitingBgImg,
        },
        {
            id: 7,
            btnTitle: "Banks and Financial Services Firms",
            svgImg: BankSvgImg,
            title: "Improve loan processing efficiency",
            content:
                "Improve loan processing efficiency, automate KYC procedures, and strengthen fraud detection capabilities using advanced data extraction and analysis techniques tailored for banks and financial services firms.",
            image: BankFinanceBgImg,
        },
    ];
    return (
        <Layout NavbarTheme={NavbarTheme}>
            <Helmet>
                <script
                    async
                    src="https://d1fmfone96g0x2.cloudfront.net/tarka-chat-2.4.0.umd.js"
                ></script>
            </Helmet>
            <Seo
                title="Intelligent Document Processing AI Solution VIDURA - Sedin"
                description="Transform document processing, enhance accuracy, efficiency & productivity with Vidura, our AI solution that automates data extraction, classification & analysis."
                image={`https://sedintechnologies.com${ogIMage}`}
            />
            <section
                className="AI-common-herostyle vidura-ai"
                style={{
                    background: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                }}
            >
                <div className="container container--banner">
                    <div className="AI-common-herostyle__text">
                        <div className="AI-common-herostyle__text-breadcrumbs text-fw-normal gap-2x text-clr-primary text-clr-white">
                            <Link className="text-decor-none" to="">
                                AI Chapter
                            </Link>
                            /
                            <Link className="text-decor-none" to="/ai-chapter/solutions">
                                Solutions
                            </Link>
                            /
                            <Link className="text-decor-none" to="">
                                Vidura
                            </Link>
                        </div>
                        <h1 className="text-h1 AI-common-herostyle__text-header text-fw-medium gap-2x text-clr-white">
                            Vidura
                        </h1>
                        <p className="text-p1 AI-common-herostyle__text-description text-fw-regular gap-2x text-clr-white">
                            Unlock the Power of Intelligent Document Understanding
                        </p>
                        <div>
                            <Link className="sdn-cta-btn" to="/contact/">
                                Contact us
                            </Link>
                        </div>
                    </div>
                    <div className="ai-demos-stats">
                        <p className="text-p1 AI-common-herostyle__text-description text-fw-regular gap-2x text-clr-white">
                            People hate paper-scrolling. Vidura loves it
                        </p>
                        <div className="ai-demos-stats__grid">
                            <div className="ai-demos-stats__grid-item">
                                <h1 className="text-h1 AI-common-herostyle__text-description text-fw-medium gap-05x text-clr-white">
                                    {/* 60% */}
                                    <CountIncrement start={0} end={60} duration={1000} />
                                </h1>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Less time to process Hundreds of invoices
                                </p>
                            </div>
                            <div className="ai-demos-stats__grid-item">
                                <h1 className="text-h1 AI-common-herostyle__text-description text-fw-medium gap-05x text-clr-white">
                                    <CountIncrement start={0} end={120} duration={4000} />
                                </h1>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Increase in claims  volume processing
                                </p>
                            </div>
                            <div className="ai-demos-stats__grid-item">
                                <h1 className="text-h1 AI-common-herostyle__text-description text-fw-medium gap-05x text-clr-white">
                                    <CountIncrement start={0} end={71} duration={1500} />
                                </h1>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Reduced time spent on HR Processes
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="leverage-genAI-content demos-content">
                <div className="container container--first">
                    <div className="leverage-genAI-content__text">
                        <div className="text-h1 text-fw-medium gap-3x">
                            Simplify Support and Enhance Efficiency with AI-Driven Document
                            Understanding
                        </div>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-1x">
                            At Sedin, we believe in simplifying support and enhancing efficiency
                            through cutting-edge technology. Introducing our revolutionary solution
                            - Versatile Intelligent Document Understanding and Retrieval Agent
                            (VIDURA).
                        </p>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-2x">
                            Vidura is your ultimate tool for simplifying support and streamlining
                            document retrieval processes. Powered by Retrieval Augmented Generation
                            (RAG) technology, it is designed to revolutionize the way you access and
                            utilize information from vast document libraries and provide precise
                            answers based on context and past interactions. With Vidura, say goodbye
                            to manual document searches and hello to instant access to the
                            information you need.
                        </p>
                    </div>
                </div>
            </section>
            {/* vidura explained  */}
            <section className="demo-explain-sec">
                <div className="container container--mid-v2">
                    <div className="leverage-genAI-content__text">
                        <div className="text-h1 text-fw-medium gap-4x explain-word">
                            Vidura Expl <span className="AI-word">ai</span>ned
                        </div>
                    </div>
                    <div className="">
                        <ExplainedDemoAI ListOfExplainAI={ListOfExplainAI} />
                    </div>
                </div>
            </section>
            <section className="Demos-video-sec">
                <div className="container container--mid-v2">
                    <div className="leverage-genAI-content__text">
                        <div className="text-h1 text-fw-medium gap-4x">
                            Experience the Future of Document Management with Vidura AI Solution
                        </div>
                        <div className="Demos-video-sec__demovideo">
                            <div className="ai-solutions-wrapper-list-item__restitems__inner-video">
                                <video
                                    loop
                                    autoPlay
                                    muted
                                    playsInline
                                    controlsList="nodownload"
                                    className="ai-video gap-2x"
                                >
                                    <source src={AppViduraVideo} type="video/mp4" />
                                </video>
                            </div>
                            <div>
                                <button
                                    onClick={(e) => handleViduraChatbot(e)}
                                    target="no_refer"
                                    className="sdn-cta-btn"
                                >
                                    Try Vidura Now
                                </button>
                                {showViduraChatbot && <ViduraChatbotWrapper />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* features of vidura  */}
            <section className="features-of-demos">
                <div className="container container--mid-v2">
                    <div className="features-of-demos__wrapper">
                        <div className="features-of-demos__grid">
                            <div className="features-of-demos__grid-item-left">
                                <div className="sticky-container">
                                    <h2 className="text-h1 text-fw-medium text-clr-primary gap-2x">
                                        Features of Vidura
                                    </h2>
                                    <div className="feature-img">
                                        <img
                                            src={FeatureImgVidura}
                                            alt="feature-img"
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="features-of-demos__grid-item-right-containerbox">
                                <FeaturesOfDemos featuresList={ListOfViduraFeatures} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why-demos-sec">
                <div className="container container--mid-v2">
                    <div className="text-h1 text-fw-medium gap-4x">Why Vidura?</div>
                    <div className="why-demos-sec__grid">
                        <div className="why-demos-sec__grid-item">
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: DocumentUnderstanding,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-p2 text-fw-medium text-clr-primary gap-1x">
                                    Document Understanding
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    It leverages advanced AI algorithms to quickly digest complex
                                    document libraries, providing precise answers tailored to your
                                    specific queries.
                                </p>
                            </div>
                        </div>
                        <div className="why-demos-sec__grid-item">
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: ContextualInsights,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                    Contextual Insights
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Context is key. Our intelligent agent takes into account past
                                    interactions and contextual cues to deliver highly relevant and
                                    accurate responses.
                                </p>
                            </div>
                        </div>
                        <div className="why-demos-sec__grid-item">
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: StreamLineSupport,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                    Streamlined Support
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Our AI solution automates the document retrieval process, saving
                                    you time and effort while ensuring seamless support experiences.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="navigate-cta">
                <div className="container container--last-v2">
                    <NavigateBlockV2
                        button="Contact us"
                        title="Say hello to simplified support and enhanced productivity with Vidura AI Solution."
                    />
                </div>
            </section>
        </Layout>
    );
};

export default IDPVidura;
