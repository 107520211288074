import React, { useState } from "react";

const ExplainedDemoAI = ({ ListOfExplainAI, btnTitle }) => {
    const [selectedBtnTitle, setSelectedBtnTitle] = useState(ListOfExplainAI[0]);
    const handleBtntitleClick = (btnTitle) => {
        setSelectedBtnTitle(btnTitle);
    };
    return (
        <>
            <div className="demo-explain-sec-grid">
                <div className="demo-explain-sec-grid__left">
                    {ListOfExplainAI?.map((item, id) => (
                        <div className="demo-explain-sec-grid__left-box" key={id}>
                            <button
                                className={`explain-btn ${
                                    selectedBtnTitle.btnTitle === item.btnTitle ? "active" : ""
                                }`}
                                onClick={() => handleBtntitleClick(item)}
                            >
                                {item.btnTitle}
                            </button>
                        </div>
                    ))}
                </div>
                <div className="demo-explain-sec-grid__right">
                    <div
                        className="content-background"
                        style={{
                            // background: `url(${selectedBtnTitle.image})`,
                            backgroundImage: `url(${selectedBtnTitle.image})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                        }}
                    >
                        <div className="content-body">
                            <div className="flex flex-jc-sb gap-1x">
                                <img src={selectedBtnTitle.svgImg} alt="svgImg" loading="lazy" />
                                <span></span>
                            </div>
                            <h2 className="text-h2 text-fw-medium gap-1x">
                                {selectedBtnTitle.title}
                            </h2>
                            <p className="text-p2 text-fw-normal gap-5x">
                                {selectedBtnTitle.content}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExplainedDemoAI;
